import.meta.glob([
    '../../images/**',
]);

const publicHome = () => import(/* webpackChunkName: "public.home" */'@js/public/home')
if (document.querySelector('#publicHome')) {
	publicHome().then(e => {
        e.default()
    })
}
